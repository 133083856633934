<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.suppliers')">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.addModal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('material.suppliers.add_supplier') }}
                    </b-button>
                </b-col>
            </b-row>
            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="suppliers" @deleteRow="remove" @editRow="editRow" @edit="edit"/>
        </b-card>

        <AddSupplier ref="addModal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddSupplier from '@/views/Supplier/AddSupplier'
    import {BButton, BCard, BCol, BOverlay, BRow} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddSupplier, BRow, BCol, BCard, BOverlay, BButton},
        data() {
            return {
                columnDefs: [
                    { headerName: this.$t('table_fields.name'), field: 'name', filter: true},
                    { headerName: this.$t('table_fields.address'), field: 'address', filter: true},
                    { headerName: this.$t('table_fields.country'), field: 'country', filter: true},
                    { headerName: this.$t('table_fields.tax_number'), field: 'tax_number', editable: false, filter: true},
                    { headerName: this.$t('table_fields.contact_person'), field: 'contacts', filter: false, editable: false, cellRenderer: (params) => this.getContact(params.value)},
                    { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150}
                ],
                suppliers: [],
                showLoader: false,
                arraysLoad: false
            }
        },
        methods: {
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = await this.$http.get('/api/client/v1/suppliers/')
                    this.suppliers = response1.data ?? []
                    await this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/suppliers/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/suppliers/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                    this.itemChange()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getContact(contact) {
                if (!contact) return '/'
                else return contact.map(option => { return `${option.name}` }).join(', ')
            }
            /*getVatName(value) {
        if (value) {
            const item = this.vats.find(x => x.id === value)
            if (item) return item.name
        }
        return '/'
    }*/
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
